import AuthService from '@/services/auth.service'
import jwtDecode from 'jwt-decode'

// Try catch statement to get the user from local storage
// If the user is not in local storage, set the user to null
let user = null
const token = localStorage.getItem('token')
try {
  if (token) {
    console.log('auth.module.js, token: ', jwtDecode(token))
    user = jwtDecode(token)
  } else {
    console.log('auth.module.js, User not logged in, token: ', token)
  }
} catch (error) {
  user = null
  console.log('auth.module.js, error: ', error)
}

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

console.log('initialState: ', initialState)

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
  },
  mutations: {
    loginSuccess(state) {
      state.status.loggedIn = true
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
      localStorage.removeItem('token')
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
  },
  getters: {
    isLoggedIn: (state) => state.status.loggedIn,
  },
}
