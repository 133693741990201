import mailfurnaceApi from '@/services/api'
import jwtDecode from 'jwt-decode'

class AuthService {
  login(user) {
    try {
      return mailfurnaceApi
        .post('auth/login', {
          username: user.username,
          password: user.password,
        })
        .then((response) => {
          console.log('auth.service.js, response: ', response)
          if (response.data.token) {
            console.log('Setting token data in local storage')
            localStorage.removeItem('token')
            localStorage.setItem('token', response.data.token)

            // Decode the token so that we can get the user info
            let decoded_token = jwtDecode(localStorage.getItem('token'))
            console.log(decoded_token)

            // Set the user info in the store
            // this.$store.dispatch('setUserInfo', decoded_token)

            // localStorage.setItem('user', JSON.stringify(response.data.user))
            // localStorage.setItem('authenticated', true)
          } else {
            localStorage.removeItem('token')
            console.log('No token provided (auth.service.js, login)')
          }

          return response.data
        })
    } catch (error) {
      console.log('auth.service.js, error: ', error)
    }
  }

  logout() {
    localStorage.removeItem('user')
  }

  register(user) {
    return mailfurnaceApi.post('signup', {
      username: user.username,
      email: user.email,
      password: user.password,
    })
  }
}

export default new AuthService()
