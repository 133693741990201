export default function authHeader() {
  let token = localStorage.getItem('token')

  if (token) {
    return { Authorization: 'Bearer ' + token }
  } else {
    console.log('No token found in auth-header.js')
    return {}
  }
}
