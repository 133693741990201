import axios from 'axios'
// import router from '@/router'

// Create an axios instance
const mailfurnaceApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  // withCredentials: true,
  timeout: 40000,
  // xsrfCookieName: 'csrf_access_token',
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('user'),
  },
})

// const COOKIE_EXPIRED_MSG = 'Token has expired'

// mailfurnaceApi.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   async (error) => {
//     const error_message = error.response.data.msg

//     switch (error.response.status) {
//       case 401:
//         if (!error.config.retry && error_message === COOKIE_EXPIRED_MSG) {
//           error.config.retry = true
//           mailfurnaceApi.defaults.xsrfCookieName = 'csrf_refresh_token'

//           await mailfurnaceApi.post('/refresh_token')
//           mailfurnaceApi.defaults.xsrfCookieName = 'csrf_access_token'

//           return mailfurnaceApi(error.config)
//         }
//         break
//       case 404:
//         router.push('/pages/404')
//         break
//       default:
//         break
//     }
//     return error.response
//   },
// )

export default mailfurnaceApi
