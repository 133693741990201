import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueCookie from 'vue-cookie'
// import VeeValidate from 'vee-validate'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

// Primvue Components
import PrimeVue from 'primevue/config'
import Skeleton from 'primevue/skeleton'
import 'primevue/resources/themes/saga-blue/theme.css' // theme
import 'primevue/resources/primevue.min.css' // core css
// import 'primeicons/primeicons.css' // icons

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(PrimeVue)
// app.use(VeeValidate)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('Skeleton', Skeleton)
// app.use(VueCookie)

app.mount('#app')
