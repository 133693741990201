<template>
  <CFooter>
    <div>
      <a href="https://greenknight.ca/" target="_blank">Greenknight</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} Green Knight Marketing.</span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://greenknight.ca/">CoreUI for Vue</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
