<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand> -->
      <!-- <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav> -->
      <CHeaderNav>
        <CNavItem>
          <!-- @change="changeMailbox"  -->
          <CFormSelect
            v-model="selectedMailbox"
            @change="changeMailbox"
            aria-label="Default select example"
          >
            <!-- <option v-for="(address, index) in mailboxArray" :key="index" :value="address">
              {{ address }}
            </option> -->
            <option
              v-for="(mailbox, index) in Object.values(mailboxes)"
              :key="index"
              :value="mailbox.email_address"
            >
              {{ mailbox.email_address }}
            </option>
          </CFormSelect>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <!-- <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem> -->
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
// import { CButton } from '@coreui/vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ref, watch } from 'vue'
// import router from '@/router'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    // CButton, // Add CButton component here
  },
  setup() {
    const store = useStore()
    let selectedMailbox = ref('')

    // Use the mailboxes getter to get the mailboxes
    const mailboxes = computed(() => store.getters.getAllMailboxes)
    console.log('Mailboxes:', mailboxes.value)

    // loop over the mailbox addresses and save the emails addresses like [address1, address2, etc]
    // const mailboxArray = mailboxes.value.map((mailbox) => mailbox.email_address)
    // console.log('mailboxArray:', mailboxArray)

    // we set the first mailbox as the default mailbox
    // Check if mailboxes exist and have at least one item
    if (mailboxes.value && mailboxes.value.length > 0) {
      console.log('Mailboxes exist:', mailboxes.value)
      selectedMailbox.value = mailboxes.value[0] // Assuming this is the value format you want.

      console.log('(appheader) Initial selected mailbox:', selectedMailbox.value)
      store.dispatch('setSelectedMailbox', selectedMailbox.value)
    } else {
      // console.log('No mailboxes exist, forwarding to create mailbox page')
      selectedMailbox.value = null // Or some default/fallback value that makes sense.
      // router.push({ name: 'CreateMailbox' })
    }

    watch(
      mailboxes,
      (newMailboxes) => {
        console.log('Selected mailbox is:', selectedMailbox.value)
        // This will run not only immediately due to { immediate: true }, but also reactively every time 'mailboxes' changes.
        if (newMailboxes && newMailboxes.length > 0 && !selectedMailbox.value) {
          // Now safe to assume 'mailboxes' is populated.
          selectedMailbox.value = newMailboxes.email_address
          store.dispatch('setSelectedMailbox', newMailboxes[0])
        }
      },
      { immediate: true },
    )

    function changeMailbox(event) {
      selectedMailbox.value = event.target.value
      console.log('changeMailbox called - Selected mailbox:', selectedMailbox.value)

      // convert mailboxes to an array
      let mailboxes_array = Object.values(mailboxes.value)
      console.log('Mailboxes array:', mailboxes_array)

      // get the mailbox info from the mailboxes array using the email address
      const selected_mailbox = Object.values(mailboxes.value).find(
        (mailbox) => mailbox.email_address === selectedMailbox.value,
      )
      console.log('Searched for selected mailbox: ', selected_mailbox)

      console.log('event.target.value:', selected_mailbox)
      store.dispatch('setSelectedMailbox', selected_mailbox)
    }

    // onMounted(() => {
    //   store.dispatch('fetchMailboxes')
    // })

    // function handleMailboxChange(event) {
    //   console.log(
    //     'handleMailboxChange called, selected mailbox:',
    //     selectedMailbox,
    //     event.target.value,
    //   )
    //   store.commit('setSelectedMailbox', event.target.value) //event.target.value)
    // }

    // Set the state of the first mailbox on startup of the dashboard (but wait for the mailboxes to be loaded
    // if (mailboxes.value && mailboxes.value.length > 0) {
    //   store.commit('setSelectedMailbox', mailboxes.value[0])
    // }
    // store.commit('setSelectedMailbox', store.getters.mailboxes[0])

    return {
      logo,
      mailboxes,
      // handleMailboxChange,
      selectedMailbox,
      changeMailbox,
      // mailboxArray,
    }
  },
  mounted() {
    if (this.mailboxes.length > 0) {
      this.selectedMailbox = this.mailboxes[0].email_address
    } else {
      this.selectedMailbox = null
      console.log('No mailboxes exist:', this.mailboxes)
    }
  },
}
</script>
